import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as S from './LockInSelectorV3.styles';
import FunnelContext from '../../context/FunnelContext';
import { ProductPrice } from '../../utils/productPrice';

const LockInSelector = props => {
  const {
    children,
    periods,
    filterBySize = false,
    defaultFilterBySize = 'small'
  } = props;

  const {
    extraObjects,
    setExtraObjects,
    currentCategory,
    setCurrentCategory,
    currentProduct,
    setCurrentProduct,
    currency,
    subscription,
    onetime,
    setTubIndexChecked,
    currentTubIndex,
    setBillingFrequency,
    tag: productSize,
    setTag: setProductSize
  } = useContext(FunnelContext);

  const currencySymbol = currency?.symbol || `$`;
  const [currentPeriod, setCurrentPeriod] = useState('1-month');
  const [init, setInit] = useState(false);

  const ProductData = useCallback(
    (category, filterByTag) => {
      let productData = [];

      if (category === 'subscriptions') {
        productData = subscription?.products.map((product, index) => {
          product.index = index;
          return product;
        });
      } else {
        productData = onetime?.products.map((product, index) => {
          product.index = index;
          return product;
        });
      }

      if (filterBySize) {
        productData = productData?.filter(({ tags }) => {
          return tags.includes(productSize);
        });
      }

      if (filterByTag) {
        productData = productData?.filter(({ tags }) => {
          return tags.includes(filterByTag);
        });
      }

      if (category === 'subscriptions') {
        productData = productData?.filter((product, i) => {
          return currentProduct === `subscription_${i}`;
        });
      } else {
        productData = productData?.filter((product, i) => {
          return currentProduct === `onetime_${i}`;
        });
      }
      const [data] = productData;
      return data;
    },
    [subscription, onetime, currentProduct, filterBySize, productSize]
  );

  useEffect(() => {
    if (!init) {
      setInit(true);
      setProductSize(defaultFilterBySize);
      let [selected] = periods.filter(item => item.selected);
      if (!selected) {
        if (periods[0]) {
          setCurrentPeriod(periods[0]?.value ?? '1-month');
        }
      } else {
        setCurrentPeriod(selected.value ?? '1-month');
        setBillingFrequency(selected.billingFrequency ?? null);
        setExtraObjects(
          Object.assign(extraObjects, {
            sub_lock_in_enabled: true,
            sub_lock_in_schedule: selected.value ?? '1-month',
            overrideBumpOfferCategory: selected?.overrideBumpOfferCategory,
            bumpOfferCategory: selected?.bumpOfferCategory ?? 'subscriptions'
          })
        );
        const category = selected.category ?? 'subscriptions';
        setCurrentCategory(category);
        setCurrentProduct(
          `${
            category === 'subscriptions' ? 'subscription' : 'onetime'
          }_${currentTubIndex}`
        );
      }
    }
    const productData = ProductData(currentCategory, currentPeriod);
    if (productData) {
      setTubIndexChecked(true);
      setExtraObjects(
        Object.assign(extraObjects, {
          tub_index_override: productData.index
        })
      );
    }
  }, [
    init,
    setInit,
    periods,
    extraObjects,
    setExtraObjects,
    setCurrentPeriod,
    currentCategory,
    currentProduct,
    currentPeriod,
    ProductData,
    setTubIndexChecked,
    setCurrentCategory,
    setCurrentProduct,
    currentTubIndex,
    setBillingFrequency,
    setProductSize,
    defaultFilterBySize
  ]);

  const handleClick = selected => {
    const {
      overrideBumpOfferCategory = false,
      bumpOfferCategory = 'subscriptions'
    } = selected;

    setCurrentPeriod(selected.value ?? '1-month');
    setBillingFrequency(selected.billingFrequency ?? null);
    setExtraObjects(
      Object.assign(extraObjects, {
        sub_lock_in_enabled: true,
        sub_lock_in_schedule: selected.value ?? '1-month',
        tub_index_override: selected?.productData?.index ?? 0,
        overrideBumpOfferCategory,
        bumpOfferCategory
      })
    );
    setTubIndexChecked(true);
    const category = selected.category ?? 'subscriptions';
    setCurrentCategory(category);
    setCurrentProduct(
      `${
        category === 'subscriptions' ? 'subscription' : 'onetime'
      }_${currentTubIndex}`
    );
  };

  const templateText = (
    text,
    chewsPerTub,
    lockin,
    periodLength,
    category,
    productId,
    isMathFloor
  ) => {
    let total_price = ProductPrice({
      type: `price`,
      currentCategory: category,
      currentProduct: productId,
      filterByTag: lockin,
      filterBySize,
      productSize
    });
    let quantity = ProductPrice({
      type: `quantity`,
      currentCategory: category,
      currentProduct: productId,
      filterByTag: lockin,
      filterBySize,
      productSize
    });
    let price = total_price / periodLength;
    let total_discounted_price = ProductPrice({
      type: `discounted_price`,
      currentCategory: category,
      currentProduct: productId,
      filterByTag: lockin,
      filterBySize,
      productSize
    });
    let discounted_price = total_discounted_price / periodLength;
    let price_per_chew =
      parseFloat(discounted_price) / parseInt(chewsPerTub) / quantity;
    let price_per_day = parseFloat(discounted_price) / 30;

    if (isMathFloor) {
      price_per_chew = Math.floor(price_per_chew * 100) / 100;
      price_per_day = Math.floor(price_per_day * 100) / 100;
    }

    let newText = text;
    newText = newText?.replace(/\[price\]/gm, parseFloat(price).toFixed(2));
    newText = newText?.replace(
      /\[discounted-price\]/gm,
      parseFloat(discounted_price).toFixed(2)
    );
    newText = newText?.replace(/\[currency-symbol\]/gm, currencySymbol);
    newText = newText?.replace(
      /\[price-per-chew\]/gm,
      parseFloat(price_per_chew).toFixed(2)
    );
    newText = newText?.replace(
      /\[price-per-day\]/gm,
      parseFloat(price_per_day).toFixed(2)
    );

    newText = newText?.replace(
      /\[price-comparison\]/gm,
      `<span class='strike'>${currencySymbol}${parseFloat(price).toFixed(
        2
      )}</span> ${currencySymbol}${parseFloat(discounted_price).toFixed(2)}`
    );

    newText = newText?.replace(
      /\[total-price-comparison\]/gm,
      `<span class='strike'>${currencySymbol}${parseFloat(total_price).toFixed(
        2
      )}</span> ${currencySymbol}${parseFloat(total_discounted_price).toFixed(
        2
      )}`
    );

    return newText;
  };

  return (
    <S.Container>
      <S.ListContainer>
        {periods.map((item, index) => {
          const { isMathFloorPerMonth, hasBorder } = item;
          const category = item.category ?? 'subscriptions';
          const productId = `${
            category === 'subscriptions' ? 'subscription' : 'onetime'
          }_${currentTubIndex}`;
          const chewsPerTub = item.chewsPerTub ?? 30;
          const lockin = item.value ?? '1-month';
          const periodLength = item.periodLength ?? 1;

          const applyTemplateText = text =>
            templateText(
              text,
              chewsPerTub,
              lockin,
              periodLength,
              category,
              productId,
              isMathFloorPerMonth
            );

          item.productData = ProductPrice({
            type: `data`,
            currentCategory: category,
            currentProduct: productId,
            filterByTag: lockin
          });

          const NoChildComponent = () => {
            return (
              <>
                <S.Row className="top">
                  <S.Left className="title">
                    <div className="bold line0">{item.title}</div>
                    {item.line1 && (
                      <div className="line1">
                        {applyTemplateText(item.line1)}
                      </div>
                    )}
                    {item.line2 && (
                      <div className="line2">
                        {applyTemplateText(item.line2)}
                      </div>
                    )}
                    {item.line3 && (
                      <div
                        className="line3"
                        dangerouslySetInnerHTML={{
                          __html: applyTemplateText(item.line3)
                        }}
                      />
                    )}
                    <div className="subtitle">{item.subTitle}</div>
                  </S.Left>
                  <S.Right>
                    {item.titleDiscount && (
                      <div className="discount">
                        <div>{item.titleDiscount}</div>
                      </div>
                    )}
                    {item.pricePerMonth && (
                      <div className="price-per-month">
                        {applyTemplateText(item.pricePerMonth)}
                      </div>
                    )}
                  </S.Right>
                </S.Row>
              </>
            );
          };

          return (
            <S.ListItem
              onClick={() => handleClick(item)}
              key={index}
              className={`${item.value === currentPeriod ? 'active' : ''} ${
                hasBorder ? `has-border` : ``
              }`}
            >
              <S.InputRadio
                className={`${item.value === currentPeriod ? 'active' : ''}`}
              ></S.InputRadio>
              {(children && children[index]) ?? NoChildComponent()}
            </S.ListItem>
          );
        })}
      </S.ListContainer>
    </S.Container>
  );
};

export default LockInSelector;
